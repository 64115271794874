// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-experience-js": () => import("./../../../src/templates/Experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-past-exhibit-js": () => import("./../../../src/templates/PastExhibit.js" /* webpackChunkName: "component---src-templates-past-exhibit-js" */),
  "component---src-templates-programs-js": () => import("./../../../src/templates/Programs.js" /* webpackChunkName: "component---src-templates-programs-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-special-exhibit-js": () => import("./../../../src/templates/SpecialExhibit.js" /* webpackChunkName: "component---src-templates-special-exhibit-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/Stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/Support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

